.Discover {
  display: grid;

  .Head {
    font-family: "Poppins", sans-serif;

    .Title {
      font-size: 36px;
      font-weight: 500;
      color: var(--icon-primary);

      @media (max-width: 650px) {
        font-size: 30px;
      }
      @media (max-width: 450px) {
        font-size: 26px;
      }
    }

    .Subtitle {
      font-size: 22px;
      font-weight: 300;
      color: var(--text-secondary);
      display: flex;
      flex-wrap: wrap;
      gap: 25px;

      @media (max-width: 650px) {
        font-size: 18px;
        margin-top: 10px;
        flex-direction: column;
        gap: 5px;
      }
      @media (max-width: 450px) {
        font-size: 16px;
      }
    }

    .BackButton {
      font-weight: 400;
      color: var(--text-secondary);
      font-size: 16px;
      padding: 4px 15px;
      border-radius: 10px;
      background: var(--background-border);
      display: inline-flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      user-select: none;
      width: max-content;

      @media (max-width: 650px) {
        font-size: 14px;
      }

      &:hover {
        color: var(--icon-primary);
      }
    }

  }

  .JumpButton {
    z-index: 2;
    position: fixed;
    bottom: 2.5vw;
    right: 2.5vw;
    width: 50px;
    height: 50px;
    color: var(--icon-primary);
    background: var(--background-popup-secondary);
    font-size: 24px;
    border-radius: 100px;
    place-items: center;
    cursor: pointer;
    user-select: none;
    transition: color ease .1s;
    display: none;

    &.Visible {
      display: grid;
      animation: jump-button-fadein .25s ease-out;
    }


    &:not(.Visible) {
      animation: jump-button-fadeout .25s ease-out forwards;
    }

    @keyframes jump-button-fadein {
      from {
        opacity: 0;
        transform: translateY(-3px);
      }
      to {
        opacity: 1;
      }
    }
    @keyframes jump-button-fadeout {
      from {
        display: grid;
        opacity: 1;
      }
      to {
        display: none;
        opacity: 0;
        transform: translateY(3px);
      }
    }

    &:hover {
      color: var(--text-primary);
    }
  }

  .Divider {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;

    > hr {
      margin: 25px 0;
      border: var(--background-border) solid 1px;
    }
  }

  .Filter {
    margin: auto 0 auto auto;
    color: var(--text-secondary);
    font-family: "Poppins", sans-serif;
    position: relative;

    .FilterButton {
      font-weight: 400;
      font-size: 15px;
      padding: 2px 12px;
      background: var(--background-border);
      border-radius: 20px;
      user-select: none;
      cursor: pointer;

      &:hover {
        color: var(--icon-primary);
      }

      @media (max-width: 650px) {
        font-size: 14px;
      }

      svg {
        font-size: .9em;
        margin: auto 0;
      }

      svg:first-of-type {
        margin-right: 4px;
      }
    }

    &.Visible .FilterMenu {
      display: flex;
      flex-direction: column;
      animation: filter-fadein ease .3s;
    }

    .FilterMenu {
      z-index: 1;
      display: none;
      gap: 12px;
      position: absolute;
      margin-top: 5px;
      right: 0;
      background: var(--background-border);
      border-radius: 10px;
      padding: 5px 15px;
      min-width: 100%;
      color: var(--text-primary);

      @keyframes filter-fadein {
        from {
          transform: translateY(-10px) rotate(1deg);
          opacity: 0;
        }
      }

      @media (min-width: 650px) {
        padding: 10px 20px;
      }
      @media (min-width: 1000px) {
        padding: 12px 25px;
      }

      hr {
        border: none;
        height: 1px;
        width: 30%;
        margin: 0 auto;
        background: var(--background-popup-secondary);
        border-radius: 1px;
      }

      .Entry {
        > p {
          font-size: 14px;
          text-align: left;
          margin-bottom: 2px;
          color: var(--text-secondary);
          font-weight: 500;

          @media (max-width: 650px) {
            font-size: 13px;
          }
        }

        .Options {
          display: flex;
          flex-direction: column;
          gap: 6px;
          border-radius: 5px;

          .Option {
            &.Selected {
              color: var(--text-primary);
              background-color: var(--background-popup-secondary);
            }

            &:hover {
              color: var(--text-primary);
            }

            color: var(--icon-primary);
            align-items: center;
            user-select: none;
            cursor: pointer;
            font-size: 18px;
            line-height: 1em;
            padding: 5px 15px;
            border-radius: 5px;
            display: flex;
            gap: 10px;

            @media (max-width: 650px) {
              font-size: 16px;
            }

            svg {
              width: 1.125em;
              height: 1.125em;
            }

            p {
              font-size: 14px;
              white-space: nowrap;

              @media (max-width: 650px) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

  }

  .List {
    justify-content: space-between;
    min-height: unset;

    .Item {
      position: relative;

      .Overlay {
        user-select: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        border-radius: 10px;

        > * {
          padding: 5px;
        }

        .Number {
          font-family: "Quicksand", sans-serif;
          font-size: 40px;
          font-weight: 600;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: var(--text-primary);
        }

        svg {
          color: var(--icon-primary);
          font-size: 22px;
          position: absolute;
          top: 3%;
          transition: transform ease .2s;

          &:hover {
            transform: scale(1.1);
          }
        }

        .Delete {
          left: 3%;
        }

        .Edit {
          right: 3%;
        }
      }
    }
  }

  .Loader {
    margin-top: 50px;
  }

}