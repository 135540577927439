
.Search {
  display: grid;
  place-items: center;
}

.SearchRow {
  width: 100%;
  display: flex;
  gap: 10px;

  .DiscoverButton {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 18px;
    color: var(--icon-primary);
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    background: var(--background-border);
    padding: 0 25px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;

    @media (max-width: 500px) {
      padding: 0 15px;
    }

    p {
      color: var(--text-secondary);
      font-weight: 500;

      @media (max-width: 800px) {
        display: none;
      }
    }


    transition: ease-out 0.2s;

    &:hover {
      transform: scale(.95);
    }
  }

}

.SearchBar {
  display: flex;
  gap: 15px;
  color: var(--text-primary);
  font-size: 20px;
  padding: 4px 20px;
  border: var(--background-border) solid 2px;
  border-radius: 8px;

  width: 100%;
  box-sizing: border-box;

  @media (max-width: 500px) {
    font-size: 18px;
  }

  input {
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: inherit;
    color: inherit;
    background: none;
    border: none;

    &.Disabled {
      filter: brightness(55%);
      cursor: not-allowed;
    }
  }

  svg {
    margin: auto;
    color: var(--icon-primary);
  }

  .Clear {
    cursor: pointer;
    font-size: 15px;
    margin-right: -5px;
  }

}


.SearchResultsContainer {
  width: 100%;
  z-index: 2;
  position: relative;

  @media (max-width: 650px) {
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .SearchResults {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background: var(--background-popup);
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    user-select: none;
    overflow-y: scroll;
    max-height: 300px;
    margin-top: 5px;

    .None {
      font-size: 20px;
      color: var(--icon-primary);
      font-family: "Poppins", sans-serif;
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      padding: 11px 0;

      svg {
        color: var(--text-secondary);
        font-size: 1.25em;
      }

      @media (max-width: 650px) {
        font-size: 18px;
      }
    }

    .Result {
      display: flex;
      gap: 10px;
      font-weight: 300;
      font-family: "Poppins", sans-serif;

      .Poster {
        height: 55px;
        width: 36.66px;
        border-radius: 5px;

        &.UnknownThumbnail {
          background: var(--background-border-dark);
          display: grid;
          place-items: center;
          color: var(--background-popup-secondary);
        }
      }

      .Name {
        color: var(--text-primary);
        font-size: 20px;
        margin: auto 0;

        @media (max-width: 650px) {
          font-size: 17px;
        }
      }

      .OriginalName {
        color: var(--text-secondary);
        font-size: 16px;
        margin: auto 0;
        @media (max-width: 650px) {
          font-size: 12px;
        }
      }

      .Type {
        justify-self: right;
        margin: auto 0 auto auto;
        color: var(--text-secondary);
      }

      transition: all .1s ease;

      &:hover {
        .Name {
          font-weight: 500;
          letter-spacing: .5px;
        }
      }

    }

  }
}
