.ImportModal {

  .ModalTitle {
    font-size: 30px;
    text-align: center;
    color: var(--text-primary);
    font-family: "Poppins", sans-serif;
  }

  .Explanation {
    white-space: pre-line;
    text-align: center;
    color: var(--text-secondary);
    font-family: "Poppins", sans-serif;
    font-size: 17px;
	  margin-top: -20px;
  }

}