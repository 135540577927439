.Card {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
  min-height: 210px;
  box-sizing: border-box;
  padding: 15px 15px;
  border-radius: 12px;
  background: var(--background-border);
  font-family: "Poppins", sans-serif;

  @media (max-width: 500px) {
    min-height: unset;
  }

  .Select {
    font-size: 14px;
    position: relative;
    display: flex;

    svg {
      color: var(--icon-primary);
      font-size: 20px;
    }

    p {
      line-height: 1em;
      color: var(--text-primary);
    }

    .CurrentSelection, .Dropdown {
      border-radius: 10px;
      box-sizing: border-box;
      min-width: 200px;
      background: var(--background-border-dark);
    }

    .CurrentSelection {
      padding: 8px 15px;

      .Arrow {
        transition: transform ease .2s;
        margin-left: auto;
      }
    }

    .CurrentSelection, .Entry {
      display: inline-flex;
      place-items: center;
      gap: 10px;
      user-select: none;
      cursor: pointer;
    }

    &.Expanded .CurrentSelection .Arrow {
      transform: rotate(180deg);
    }

    &.Expanded .Dropdown {
      display: flex;
    }

    .Dropdown {
      padding: 10px 15px;
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      margin-top: 5px;
      flex-direction: column;
      gap: 20px;
      z-index: 1;
    }

    .Entry {
      p {
        color: var(--icon-primary);
        transition: color ease .1s;
      }

      svg {
        color: var(--text-secondary);
        transition: color ease .1s;
      }

      &:hover {
        p {
          color: var(--text-primary);
        }

        svg {
          color: var(--icon-primary);
        }
      }
    }
  }

  .Indicator {
    width: 100%;
    height: 4px;
    align-self: center;
    margin-left: auto;
    margin-right: 15px;
    border-radius: 2px;
    background: var(--text-secondary);
    position: relative;
    max-width: 20%;

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      content: "";
      background: var(--text-primary);
      height: inherit;
      border-radius: inherit;
      animation: indicator-progression linear 8s forwards;
    }

    @keyframes indicator-progression {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
  }

  .CardContent {
    margin: auto 0;
    padding: 15px 15px 0;
    display: grid;

    &:not(.NoAnimation) {
      animation: card-content-fadein ease-out .75s;
      @keyframes card-content-fadein {
        from {
          opacity: 0;
          transform: translateX(3%);
        }
        50% {
          opacity: 1;
        }
      }
    }


    &.Empty {
      place-items: center;
      gap: 16px;
      grid-template-columns: auto auto;
      color: var(--icon-primary);

      svg {
        font-size: 32px;
      }

      p {
        font-style: italic;
        font-size: 16px;
      }
    }

    &.Exhibition {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      img {
        aspect-ratio: 2 / 3;
        max-width: 80px;
        max-height: 120px;
        border-radius: 8px;
        transition: scale ease .2s, filter ease .2s;

        @media (max-width: 650px) {
          max-height: 110px;
        }
        @media (max-width: 450px) {
          max-height: 100px;
        }

        &:hover {
          scale: 1.01;
          filter: brightness(90%);
          cursor: pointer;
        }
      }

      .Info {
        display: grid;
        gap: 8px;
      }

      .Title {
        font-size: 18px;
        color: var(--text-primary);

        @media (max-width: 500px) {
          font-size: 16px;
        }
      }

      .Tagline {
        font-size: 14px;
        color: var(--text-secondary);

        @media (max-width: 500px) {
          font-size: 13px;
        }
      }

      .Stats {
        display: flex;
        gap: 25px;
        font-size: 16px;

        @media (max-width: 500px) {
          font-size: 14px;
        }

        > * {
          display: inline-flex;
          place-items: center;
          gap: 10px;
        }

        svg {
          color: var(--icon-primary);
        }

        p {
          font-weight: 500;
          color: var(--text-secondary);
        }
      }
    }

    &.FavGenre {
      gap: 1px;

      .Genre {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 12px;
        font-size: 16px;

        @media (max-width: 650px) {
          font-size: 14px;
          gap: 8px;
        }

        .Place {
          place-self: center;
          color: var(--icon-primary);
          background: var(--background-popup-secondary);
          width: 22px;
          height: 22px;
          font-weight: 600;
          display: grid;
          place-items: center;
          border-radius: 100%;
          font-size: 14px;
          line-height: 1em;

          @media (max-width: 650px) {
            width: 18px;
            height: 18px;
            font-size: 12px;
          }
        }

        &:nth-child(1) .Place {
          background: var(--rank-gold);
          color: var(--text-primary);
        }

        &:nth-child(2) .Place {
          background: var(--rank-silver);
          color: var(--text-primary);
        }

        &:nth-child(3) .Place {
          background: var(--rank-bronze);
          color: var(--text-primary);
        }

        .Title {
          color: var(--icon-primary);

        }

        .Amount {
          color: var(--text-secondary);
          font-weight: 500;
        }


      }
    }

  }

}