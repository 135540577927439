body {
  background: var(--background);
}

.App {
  position: relative;

  .Content {
    min-height: 100vh;
    padding: 40px 0 120px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0 10%;

    @media (min-width: 1900px) {
      margin: 0 12%;
    }
    @media (max-width: 650px) {
      padding-top: 20px;
      margin: 0 8%;
      gap: 30px;
    }
    @media (max-width: 400px) {
      gap: 20px;
      margin: 0 5%;
    }
  }
}

.Error {
  margin-top: 4%;
  display: flex;
  gap: 24px;
  font-family: "Poppins", sans-serif;
  justify-content: center;

  @media (min-width: 1500px) {
    margin-top: calc(4% - 25px - 2px);
    border: 2px solid var(--background-border);
    padding: 25px 0;
    border-radius: 10px;
  }

  @media (max-width: 550px) {
    margin-top: 0;
    flex-direction: column;
    align-items: start;
  }

  .Graphic {
    margin-top: 30px;
    font-size: 100px;
    color: var(--text-secondary);
    width: 1em;
    height: 1em;
    position: relative;
    display: flex;

    @media (max-width: 1000px) {
      font-size: 80px;
    }
    @media (max-width: 550px) {
      font-size: 60px;
    }

    @media (prefers-reduced-motion: no-preference) {
      svg:nth-child(1) {
        animation: error-heart-1 .25s .25s ease-in forwards;
        @keyframes error-heart-1 {
          to {
            display: none;
            scale: 1.2;
          }
          90% {
            rotate: 5deg;
          }
        }
      }
      svg:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        animation: error-heart-2 .25s .5s ease-out backwards;
        @keyframes error-heart-2 {
          from {
            display: none;
            scale: 1.25;
          }
          30% {
            rotate: -5deg;
          }
        }
      }
    }
    @media (prefers-reduced-motion) {
      svg:nth-child(1) {
        display: none;
      }
    }
  }

  h1 {
    font-size: 28px;
    color: var(--text-primary);
    font-weight: 500;
    line-height: 1em;

    @media (max-width: 1000px) {
      font-size: 22px;
    }
  }

  p {
    margin-top: 8px;
    color: var(--text-secondary);
    font-size: 16px;
    font-weight: 400;

    @media (max-width: 1000px) {
      font-size: 15px;
    }
  }

  .Buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    row-gap: 8px;
    flex-wrap: wrap;

    > a {
      color: var(--icon-primary);
      display: flex;
      gap: 8px;
      width: max-content;
      align-items: center;
      padding: 4px 16px;
      border-radius: 8px;
      background: var(--background-border);

      @media (max-width: 1000px) {
        font-size: 15px;
      }

      &:hover {
        color: var(--text-primary);
      }

      svg {
        width: 1em;
      }
    }
  }

}