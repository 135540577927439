//body {
//  	--background: #fafaff;
//  	--background-popup: #f6f8fc;
//  	--background-popup-secondary: #c7c9ce;
//  	--background-border: #edf0f8;
//  	--background-border-dark: #d7d9e3;
//
//  	--text-primary: #28282a;
//  	--text-secondary: #787880;
//  	--icon-primary: #48484b;
//
//  	--action-success: #4ebd43;
//  	--action-success-background: #37c3314c; // a=0.3
//  	--action-fail: #ef7c7c;
//  	--action-fail-background: #c744444c; // a=0.3
//
//    --rank-gold: #D2B61F;
//    --rank-silver: #ABA9A1;
//    --rank-bronze: #A56C17;
//}

body.dark {
  --background: linear-gradient(180deg, rgb(35, 35, 42), rgb(25, 25, 32));
  --background-popup: #28282d;
  --background-popup-secondary: #595961;
  --background-border: #2C2C32;
  --background-border-dark: #212125;

  --text-primary: #fff;
  --text-secondary: #828288;
  --icon-primary: #C8C8C9;

  --action-success: #37c331;
  --action-success-background: #37c3314c; // a=0.3
  --action-fail: #c74444;
  --action-fail-background: #c744444c; // a=0.3

  --rank-gold: #D2B61F;
  --rank-silver: #ABA9A1;
  --rank-bronze: #A56C17;
}

//body.dark {
//  --background: black;
//  --background-popup: #1a1a1c;
//  --background-popup-secondary: #38383d;
//  --background-border: #1f1f1f;
//  --background-border-dark: #131212;
//
//  --text-primary: #fff;
//  --text-secondary: #828288;
//  --icon-primary: #C8C8C9;
//
//  --action-success: #37c331;
//  --action-success-background: #37c3314c; // a=0.3
//  --action-fail: #c74444;
//  --action-fail-background: #c744444c; // a=0.3
//
//  --rank-gold: #D2B61F;
//  --rank-silver: #ABA9A1;
//  --rank-bronze: #A56C17;
//}
