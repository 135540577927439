@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p, h1, h3, h4, h5, h6 {
  white-space: pre-line;
}

a {
  text-decoration: none;
}

input:focus, textarea:focus, select:focus, button {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;

  @media (max-width: 650px) {
    width: 4px;
    height: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background-border);
}

div::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--icon-primary);
}
