.SimpleModal {
	width: 25% !important;
	gap: 30px !important;

	@media (max-width: 1500px) {
		width: 30% !important;
	}
	@media (max-width: 1200px) {
		width: 35% !important;
	}
	@media (max-width: 1000px) {
		width: 40% !important;
	}
	@media (max-width: 900px) {
		width: 45% !important;
	}
	@media (max-width: 800px) {
		width: 55% !important;
	}
	@media (max-width: 700px) {
		width: 60% !important;
	}
	@media (max-width: 650px) {
		width: 90% !important;
	}

	.ModalWrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;

		.ModalContent {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.ModalTitle {
				font-size: 32px;
				text-align: center;
				color: var(--text-primary);
				font-family: "Poppins", sans-serif;
			}

			.ModalBody {
				font-size: 16px;
				text-align: center;
				color: var(--text-secondary);
				font-family: "Poppins", sans-serif;
				white-space: pre-line;
			}
		}

	}

}