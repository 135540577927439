body:has(.Modal.Visible) {
  pointer-events: none;
  overflow: hidden !important;
  overscroll-behavior: contain;
  margin-right: 8px;

  @media (max-width: 650px) {
    margin-right: 4px;
  }
  @media (max-width: 450px) {
    margin-right: 0;
  }
}

.Modal {
  &:not(.Visible) {
    display: none;
    animation: modal-fadeout-wrapper .15s ease-out;

    .AnimatedModalContent, .SimplyAnimatedModalContent {
      animation: modal-fadeout .25s ease-out;
    }

    @keyframes modal-fadeout-wrapper {
      from {
        display: grid;
      }
      to {
        display: grid;
        opacity: 0;
      }
    }

    @keyframes modal-fadeout {
      to {
        transform: scale(.9);
        opacity: 0;
      }
    }
  }

  &.Visible {
    .AnimatedModalContent {
      animation: modal-fadein .3s ease-out;
    }

    .SimplyAnimatedModalContent {
      animation: simply-modal-fadein .25s ease-out;
    }

    @keyframes modal-fadein {
      from {
        transform: scale(.95) rotate(2deg);
        opacity: 0;
      }
      33% {
        opacity: 1;
      }
      75% {
        transform: scale(1.01) rotate(-0.5deg);
      }
    }
    @keyframes simply-modal-fadein {
      from {
        transform: scale(.95);
        opacity: 0;
      }
      33% {
        opacity: 1;
      }
      75% {
        transform: scale(1.01);
      }
    }
  }

  position: fixed;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, .75);
  pointer-events: auto;
  z-index: 5;

  .DefaultModalContent {
    background: var(--background-popup);
    padding: 25px 65px;
    width: 55%;
    max-height: 70%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;

    @media (max-width: 850px) {
      width: 65%;
      padding: 20px 40px;
    }

    @media (max-width: 650px) {
      width: 90%;
      max-height: 82%;
      padding: 20px 25px;
      box-sizing: border-box;
    }
  }

  .Buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: auto;

    @media (max-width: 400px) {
      gap: 7px;
    }

    .Button {
      padding: 4px 12px;
      border-radius: 6px;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      color: var(--text-primary);
      user-select: none;
      cursor: pointer;
      background: var(--background-popup-secondary);
      display: flex;
      align-items: center;
      gap: 8px;

      @media (max-width: 650px) {
        font-size: 14px;
      }

      transition: transform .25s ease, filter .1s ease;

      &:not(.Disabled):hover {
        transform: translateY(2px);
        filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, .25));
      }

      &.Save {
        background: var(--action-success) !important;
      }

      &.Remove {
        background: var(--action-fail) !important;
      }

      &.Disabled {
        filter: brightness(50%);
        cursor: not-allowed;
      }
    }


  }

  .Interact {
    display: flex;
    gap: 10%;
    justify-content: center;

    .Button {
      justify-content: center;
      width: 125px;
      aspect-ratio: 4 / 5;
      box-sizing: border-box;
      border-radius: 15px;
      border: var(--background-popup-secondary) 1px solid;
      color: var(--text-secondary);
      background: rgba(0, 0, 0, .05);
      text-align: center;
      padding: 30px 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      cursor: pointer;
      user-select: none;
      word-break: break-word;
      transition: color .2s, border-color .2s, transform .2s, scale .2s;

      &:hover {
        color: var(--text-primary);
        border-color: var(--text-secondary);
        border-width: 2px;
      }

      &.Selected {
        color: var(--text-primary);
        border-color: var(--icon-primary);
        border-width: 2px;
      }

      svg {
        font-size: 50px;

        @media (max-width: 400px) {
          font-size: 40px;
        }
      }

      div {
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;

        @media (max-width: 400px) {
          font-size: 16px;
        }
      }
    }
  }
}