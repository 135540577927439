.OgClock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -10px;;

  text-align: center;
  font-family: "Poppins", sans-serif;

  .TitleWrapper {

    .TitleNotice {
      font-family: "Quicksand", sans-serif;
      font-size: 13px;
      color: var(--text-secondary);
      transition: color ease .2s;
      cursor: pointer;

      &:hover {
        color: var(--text-primary);
      }

      position: relative;
      right: 110px;
    }

    .Title {
      font-family: "Quicksand", sans-serif;
      font-size: 20px;
      color: var(--text-secondary);
    }

  }

  .Main {
    font-size: 40px;
    color: var(--text-primary);

    @media (max-width: 800px) {
      font-size: 35px;
    }
  }

  .Or {
    font-size: 20px;
    color: var(--text-secondary);

    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 1rem;
    }
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  .Days {
    font-size: 30px;
    color: var(--text-primary);

    @media (max-width: 800px) {
      font-size: 25px;
    }
  }

  .Wage {
    font-size: 15px;
    color: var(--text-secondary);
    display: inline-flex;
    gap: .25em;

    .WageEarned {
      color: var(--icon-primary);
    }

    .WageAmount {
      cursor: pointer;
      transition: color ease .2s;

      &:hover {
        color: var(--text-primary);
      }
    }

  }


  .ButtonWrapper {
    $gap: 20px;
    margin: 0 auto 0;
    padding: 1px 10px;
    color: var(--text-secondary);
    transition: color ease .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 10px;
    gap: $gap;

    border: var(--background-border) 1px solid;
    background: var(--background-border);

    .Button {
      user-select: none;
      cursor: pointer;
      gap: 10px;
      display: flex;
      align-items: center;

      &:hover {
        color: var(--icon-primary);
      }
    }

    > .Button:not(:last-child) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        background: var(--text-secondary);
        width: 1px;
        height: 70%;
        top: 15%;
        right: -$gap / 2;
      }
    }

  }
}