
.FriendsModal {

  .ModalTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 24px;

    @media (max-width: 650px) {
      font-size: 20px;
      gap: 6px;
    }

    .Amount {
      font-family: "Poppins", sans-serif;
      color: var(--text-secondary);
      font-weight: 500;
      font-size: 16px;

      @media (max-width: 650px) {
        font-size: 14px;
      }

      &:before {
        content: "(";
        font-weight: 400;
        font-size: .9em;
      }
      &:after {
        font-size: .9em;
        content: ")";
        font-weight: 400;
      }
    }
  }

  .Empty {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: var(--text-secondary);
    text-align: center;
    padding: 10px 12px;
    background: var(--background-border);
    border-radius: 10px;
  }

  hr {
    border: none;
    background: var(--background-popup-secondary);
    border-radius: 1px;
    width: 100%;
    height: 1px;
    margin-top: -20px;
    margin-bottom: -10px;
  }

  .Entries {
    display: flex;
    gap: 8px;
    flex-direction: column;
    overflow-y: auto;

    .Entry {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 8px 16px;
      background: var(--background-border);
      border-radius: 12px;
      user-select: none;
      cursor: pointer;
      transition: transform ease .2s;

      &:hover {
        transform: scale(.99);
      }

      img {
        border-radius: 50%;
        width: 45px;
        max-height: 45px;
      }
      span {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .DisplayName {
        font-family: "Poppins", sans-serif;
        color: var(--text-primary);
        font-size: 17px;
        line-height: 1em;
      }
      .UserName {
        font-family: "Poppins", sans-serif;
        color: var(--text-secondary);
        font-size: 14px;
        line-height: 1em;
      }
    }

  }

}
