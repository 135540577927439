.LoginLoaderOverlay {
  text-align: center;
  font-family: "Poppins", sans-serif;

  .Title {
    color: var(--text-primary);
    font-size: 24px;
    position: relative;

    &:after {
      position: absolute;
      content: "...";
      animation: dots linear .75s infinite forwards;
      margin-left: 5px;
      color: var(--icon-primary);
      @keyframes dots {
        0% {
          content: ".";
        }
        50% {
          content: "..";
        }
        75%, 100% {
          content: "...";
        }
      }
    }
  }

  .Subtitle {
    color: var(--text-secondary);
    margin-bottom: 10px;
  }

  .TextButton {
    color: var(--text-secondary);
    font-size: 16px;
    font-weight: 500;
    margin-top: 25px;
    user-select: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}