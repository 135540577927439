
.Menu {

  .Head {
    display: flex;
    gap: 20px;

    .Poster {
      height: 150px;
      width: 108px;
      border-radius: 10px;

      @media (max-width: 650px) {
        height: 125px;
        width: 90px;
      }

      transition: all .3s;

      &:hover {
        transform: scale(1.025);
        filter: brightness(90%);
      }
    }

    .Info {
      display: flex;
      flex-direction: column;
      margin: auto 0;

      .Name {
        color: var(--text-primary);
        font-size: 28px;
        font-family: "Poppins", sans-serif;
        display: inline-flex;
        align-items: center;
        gap: 10px;

        svg {
          color: var(--text-secondary);
          font-size: .6em;
        }

        @media (max-width: 800px) {
          font-size: 24px;
        }
        @media (max-width: 650px) {
          font-size: 20px;
        }
        @media (max-width: 450px) {
          line-height: 1.1em;
          font-size: 18px;
        }
      }

      .Tagline {
        color: var(--text-secondary);
        font-size: 16px;
        font-family: "Quicksand", sans-serif;
        font-weight: 400;

        ::before {
          content: "•";
        }

        @media (max-width: 450px) {
          font-size: 14px;
        }
        @media (max-width: 400px) {
          font-size: 13px;
        }
      }

      .OriginalName {
        color: var(--text-secondary);
        font-size: 16px;
        font-family: "Quicksand", sans-serif;
        font-weight: 400;

        @media (max-width: 400px) {
          font-size: 14px;
        }
      }

      .Year, .Playtime {
        color: var(--text-secondary);
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        display: flex;
        gap: 6px;
        align-items: center;

        @media (max-width: 450px) {
          font-size: 14px;
        }

        svg {
          width: 1em;
        }
      }

      .Year {
        font-weight: 400;
        //@media (max-width: 390px) {
        //  display: none;
        //}
      }

      .Playtime {
        margin: auto 0;
        font-weight: 300;
      }

      span {
        margin-top: 10px;;
        display: flex;
        flex-direction: column;
        column-gap: 15px;

        @media (max-width: 650px) {
          align-items: center;
          flex-direction: row;
        }

        @media (max-width: 400px) {
          flex-direction: column;
          align-items: unset;
        }
      }
    }
  }

  .History {
    overflow-y: scroll;
    max-height: 55%;

    .Title {
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      color: var(--text-primary);


      @media (max-width: 500px) {
        font-size: 16px;
      }
    }

    .Seasons {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .Season {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 300;
        color: var(--text-secondary);

        display: flex;
        justify-content: space-between;

        .SeasonStats {
          display: flex;
          gap: 8px;
          overflow: hidden;
          white-space: nowrap;

          .Name {
            font-weight: 400;
          }

          .Episodes {
            &::before {
              content: "•";
              margin-right: 8px;
            }

            &::after {
              content: " Episodes";
              @media (max-width: 650px) {
                content: "x";
              }
            }

            @media (max-width: 450px) {
              display: none;
            }
          }

          .Runtime {
            &::before {
              content: "•";
              margin-right: 8px;
            }

            @media (max-width: 360px) {
              //display: none;
            }
          }

        }

        &:only-child .Controls {
          @media (max-width: 1500px) {
            @keyframes controls-highlight {
              0% {
                transform: scale(1);
              }
              30% {
                transform: scale(1.2);
              }
              50% {
                transform: scale(1.05);
              }
              75% {
                transform: scale(1.15);
              }
              100% {
                transform: scale(1);
              }
            }

            animation: controls-highlight ease 1.25s .5s backwards;
          }

          &:before {
            @media (min-width: 1501px) {
              content: "";
              width: 6em;
              height: 2em;
              margin-left: calc(-2px - .5em);
              position: absolute;
              z-index: 0;
              border-radius: 12px;
              border: 1px solid var(--background-popup-secondary);
              opacity: 0;

              @keyframes controls-highlight2 {
                0% {
                  opacity: 1;
                  scale: 3.33;
                }
                100% {
                  scale: .5;
                  opacity: 0;
                }
              }

              animation: controls-highlight2 .25s .5s ease-in 3, controls-highlight2 .5s calc(.25s * 3 + .5s) ease-in forwards;
            }
          }
        }

        .Controls {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-right: 2.5%;
          user-select: none;

          .Plus, .Minus {
            display: flex;
            color: var(--text-primary);
            padding: 5px;
            border-radius: 50%;
            background: var(--background-popup-secondary);
            cursor: pointer;
            scale: .95;

            &:hover {
              scale: 1;
            }

            svg {
              margin: auto;
              width: 12px;
              height: 12px;
            }

            &.Disabled {
              filter: brightness(55%);
              cursor: not-allowed;
            }
          }

          .Display {
            font-weight: 500;
            min-width: 1.5rem;
            text-align: center;
          }

        }
      }
    }
  }

  .Collection {
    //max-height: 55%;

    .Title {
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      color: var(--text-primary);
      margin-bottom: 4px;
      display: flex;
      gap: 8px;

      @media (max-width: 500px) {
        font-size: 16px;
      }

      .ApplyButton {
        padding: 4px 8px;
        margin: auto 0;
        font-size: .7em;
        color: var(--icon-primary);
        border: solid var(--background-popup-secondary) 1px;
        border-radius: 8px;
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
        line-height: 1em;

        &:hover {
          background: var(--background-popup-secondary);
        }

        svg {
          margin-right: 2px;
        }
      }
    }

    .Parts {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;
      border-radius: 10px;

      span {
        overflow-x: auto;
        display: flex;
        padding: 12px 32px;
        gap: 30px;

        @media (max-width: 650px) {
          padding: 6px 16px;
          gap: 20px;
        }
      }

      &:before {
        top: 0;
        left: 0;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        pointer-events: none;
        scale: 1.1;
      }

      .Part {
        background: rgba(255, 255, 255, .15);
        padding: 10px 20px;
        border-radius: 12px;
        backdrop-filter: blur(3px);
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        text-align: center;
        cursor: pointer;
        border: transparent solid 2px;
        transition: ease .25s;

        &:hover {
          backdrop-filter: blur(6px);
          background: rgba(255, 255, 255, .2);

          .Poster {
            transform: scale(1.04);

            &:hover {
              transform: scale(1.066);
              filter: brightness(90%);
            }
          }
        }

        &.Applied {
          //background: var(--action-success-background);
          background: rgba(255, 255, 255, .4);
        }
        &.Root {
          border-color: rgba(255, 255, 255, .5);
        }

        @media (max-width: 650px) {
          padding: 8px 18px;
        }
        @media (max-width: 450px) {
          padding: 6px 15px;
        }

        .Info {
          display: flex;
          flex-direction: column;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        .Name {
          font-family: "Poppins", sans-serif;
          color: var(--text-primary);
          line-height: 1em;
          font-size: 15px;
          max-width: 100px;
          min-width: #{70px*1.25};

          @media (max-width: 650px) {
            font-size: 14px;
          }
          @media (max-width: 450px) {
            font-size: 12px;
          }

          @media (max-width: 650px) {
            min-width: #{55px*1.25};
          }
        }

        .Poster {
          opacity: .9;
          border-radius: 14px;
          width: 70px;
          height: auto;
          aspect-ratio: 2 / 3;
          transition: ease .2s;

          @media (max-width: 650px) {
            width: 55px;
          }
          @media (max-width: 450px) {
          }
        }

        .Times {
          font-family: "Poppins", sans-serif;
          color: var(--icon-primary);
          font-size: 15px;
          padding-top: 4px;
          //margin-bottom: 0;
          //margin-top: auto;
          user-select: none;
          line-height: 1em;

          @media (max-width: 650px) {
            font-size: 13px;
            svg {
              font-size: 12px;
            }
          }
          @media (max-width: 450px) {
            font-size: 11px;
            svg {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}