.Welcome {

  @for $i from 1 through 8 {
    > :nth-child(#{$i}) {
      animation: welcome-segment-fadein ease-in .3s #{.2s * ($i - 1)} backwards;
    }
  }

  @keyframes welcome-segment-fadein {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

  .Head {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 650px) {
      margin: 20px;
    }

    .BadgeButton {
      background: var(--background-border);
      width: max-content;
      padding: 4px 12px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      color: var(--icon-primary);
      user-select: none;
      cursor: pointer;
      margin-bottom: 12px;

      svg:first-child {
        margin-right: 8px;
      }

      svg {
        transition: transform ease .2s;
        margin-left: 2px;
        font-size: 13px;
      }

      &:hover svg:last-child {
        transform: translateX(2px);
      }

    }

    .Title {
      font-family: "Poppins", sans-serif;
      font-size: 42px;
      line-height: 1em;
      font-weight: 600;
      color: var(--text-primary);
      text-align: center;
      max-width: 95%;

      @media (max-width: 650px) {
        font-size: 36px;
      }
      @media (max-width: 500px) {
        font-size: 32px;
      }
    }

    .Subtitle {
      margin-top: 12px;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      color: var(--text-secondary);
      text-align: center;

      @media (max-width: 500px) {
        font-size: 14px;
      }
    }

    span {
      display: flex;
      gap: 16px;
    }

    .LoginButton {
      margin-top: 8px;
      border: var(--background-border) solid 2px;
      border-radius: 8px;
      display: flex;
      color: var(--text-primary);
      font-family: "Poppins", sans-serif;
      font-size: 15px;
      align-items: center;
      gap: 8px;
      padding: 4px 14px;
      user-select: none;
      cursor: pointer;
      transition: background ease .2s;

      @media (max-width: 500px) {
        font-size: 14px;
      }

      svg {
        color: var(--icon-primary);
      }

      img {
        width: 1em;
        height: 1em;
        border-radius: 100%;
      }

      &:hover {
        background: var(--background-border);
      }
    }

  }

  .Options {
    padding-top: 50px;

    @media (max-width: 450px) {
      padding: 25px 0;
    }

    .Search {
      margin: auto;
      max-width: 80%;

      @media (max-width: 1000px) {
        max-width: 90%;
      }

      .SearchRow {
        .SearchBar {
          border-width: 3px;
          border-radius: 16px;
          padding: 10px 24px;

          @media (max-width: 800px) {
            padding: 8px 18px;
          }
        }

        .DiscoverButton {
          display: none;
        }
      }

      .SearchResults {
        margin-top: 8px;
        border-radius: 16px;
      }
    }

    .Or {
      color: var(--text-secondary);
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 18px;
      margin: 25px 0;
      text-align: center;
    }

    .DiscoverSection {
      font-family: "Poppins", sans-serif;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;

      @media (max-width: 1200px) {
        flex-direction: column;
        gap: 15px;
      }

      p {
        color: var(--text-secondary);

        &:nth-of-type(1) {
          font-size: 24px;
          color: var(--icon-primary);

          @media (max-width: 600px) {
            font-size: 20px;
          }
        }

        &:nth-of-type(2) {
          font-size: 18px;
          font-weight: 300;

          @media (max-width: 600px) {
            font-size: 16px;
          }
        }
      }

      div {
        font-size: 22px;
        font-weight: 500;
        padding: 13px 28px;
        width: max-content;
        border-radius: 16px;
        color: var(--text-primary);
        background: var(--background-border);
        user-select: none;
        cursor: pointer;
        line-height: 1em;

        svg {
          margin-right: 8px;
        }

        transition: transform ease .2s;

        &:hover {
          transform: scale(1.025);
        }

        @media (max-width: 600px) {
          font-size: 18px;
          padding: 10px 24px;
        }
      }
    }
  }

  hr {
    margin: 75px 0;
    border: var(--background-border) solid 1px;

    @media (max-width: 800px) {
      margin: 30px 0;
    }
  }

  .Features {
    max-width: 80%;
    margin: 0 auto;

    @media (max-width: 1400px) {
      max-width: 90%;
    }
    @media (max-width: 900px) {
      max-width: 95%;
    }
    @media (max-width: 750px) {
      max-width: unset;
    }

    .Entry:not(:first-child) {
      margin-top: 40px;
    }

    .Entry {
      gap: clamp(50px, 120px, 12%);
      display: flex;
      flex-direction: row;
      text-align: left;
      font-family: "Poppins", sans-serif;

      &:nth-child(2n) {
        flex-direction: row-reverse;
      }

      @media (max-width: 1000px) {
        gap: 50px;
      }
      @media (max-width: 750px) {
        gap: 30px;
      }
      @media (max-width: 550px) {
        gap: 10px;
        &:nth-child(n) {
          flex-direction: column;
        }
      }

      @media (prefers-reduced-motion: no-preference) {
        animation: welcome-entry-fade linear;
        animation-timeline: view();
        animation-range-end: 15dvh;
        animation-range-start: 0;
      }

      @keyframes welcome-entry-fade {
        from {
          opacity: 0;
          transform: translateY(0%) scale(90%);
        }
      }

      span {
        display: flex;
        flex-direction: column;
      }

      .Title {
        color: var(--text-primary);
        font-size: 28px;
        font-weight: 500;
        margin-top: 20px;

        @media (max-width: 1000px) {
          margin-top: 10px;
          font-size: 22px;
        }
        @media (max-width: 750px) {
          font-size: 20px;
        }

        svg {
          color: var(--text-secondary);
          margin-right: 5px;
        }
      }

      .Explanation {
        color: var(--text-secondary);
        margin: 25px 0;

        @media (max-width: 1000px) {
          margin: 10px 0;
          font-size: 15px;
        }
        @media (max-width: 750px) {
          font-size: 14px;
        }
      }

      .Button {
        width: max-content;
        padding: 6px 20px;
        color: var(--icon-primary);
        background: var(--background-border);
        border-radius: 8px;
        user-select: none;
        cursor: pointer;
        transition: background ease .2s, color ease .2s;

        @media (max-width: 1000px) {
          padding: 5px 15px;
          font-size: 14px;
        }

        svg {
          margin-right: 5px;
        }

        &:hover {
          background: var(--background-popup-secondary);
          color: var(--text-primary);
        }
      }

      img {
        max-width: clamp(250px, 10%, 30%);
        height: max-content;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, .2) 0 1px 15px;

        @media (max-width: 1000px) {
          max-width: 200px;
        }
        @media (max-width: 750px) {
          max-width: 145px;
        }
        @media (max-width: 550px) {
          display: none;
        }
      }
    }
  }

  .Showcase {
    .Entry:not(:first-child) {
      margin-top: 36px;
    }

    span {
      position: relative;

      &:before {
        display: block;
        content: " ";
        margin-right: 8px;
        margin-left: 3px;
        position: absolute;
        top: 0;
        bottom: 0;

        width: 2px;
        background: var(--background-border);
      }
    }

    p {
      margin-left: 20px;
      color: var(--text-secondary);
      font-size: 16px;
      font-family: "Poppins", sans-serif;

      @media (max-width: 800px) {
        margin-left: 16px;
        font-weight: 400;
        font-size: 15px;
      }
      @media (max-width: 650px) {
        font-size: 14px;
      }

      a {
        color: var(--text-primary);
      }
    }

    h1 {
      color: var(--icon-primary);
      font-size: 28px;
      font-weight: 300;
      font-family: "Poppins", sans-serif;

      @media (max-width: 800px) {
        font-size: 24px;
      }
      @media (max-width: 650px) {
        font-size: 18px;
      }

      &:after {
        content: ".";
        margin-left: 3px;
        color: var(--text-secondary);
      }
    }
  }

}
