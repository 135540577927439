.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  margin-top: auto;
  padding-top: 35px;
  text-align: center;
  color: var(--text-secondary);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background: linear-gradient(0deg, rgba(23, 23, 26, 1) 0%, rgba(29, 29, 36, 0) 90%);

  @media (max-width: 500px) {
    font-size: 15px;
  }

  a {
    color: var(--icon-primary);
    transition: color ease .1s;

    &:not(:first-child) {
      color: var(--text-secondary);
    }

    &:hover {
      color: var(--text-primary);
    }
  }

  .Credits {
    padding-bottom: 20px;
    display: inline-flex;
    justify-content: center;

    @media (max-width: 650px) {
      flex-direction: column;
      span {
        display: none;
      }
    }

    span {
      margin: 0 8px;
    }

  }
}